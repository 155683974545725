#body-pint {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: white;
}

.bar-header {
    background-color: #3b3b3b;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000
}

.bar-header button {
    margin-top: 8px;
}

.content-print {
    width: 100%;
    height: auto;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin-top: 60px;
    padding: 10mm;

}

.devider {
    border: 1px solid black;
}


@media print {
  
    @page {
        /* size: A4; */
        margin: 5mm 10mm 5mm 10mm;
    } 
    body {
        margin: 0;
        padding: 0;
    }
    .content-print {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
   

}

